import axios from "axios";
import { useAuth } from "../../context/auth-context";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Modal } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";

const Analytics = () => {
  const { user } = useAuth();

  const [pointsOptions, setPointsOptions] = useState({
    chart: {
      id: "apex Chart",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  });

  const [pointsSeries, setPointsSeries] = useState([
    {
      name: "series-1",
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
  ]);

  // const getChartDataforPerformance = () => {
  //   let skuId: any = [];
  //   let points: any = [];
  //   //eslint-disable-next-line
  //   performanceData?.map(item => {
  //     skuId.push(item.SkuUniqueId);
  //     points.push(item.PointEarned);
  //   });
  //   setPerformanceOptions({
  //     chart: {
  //       id: "Sku Wise User Performance",
  //     },
  //     xaxis: {
  //       categories: skuId,
  //     },
  //   });
  //   setPerformanceSeries([
  //     {
  //       name: "Points Earned",
  //       data: points,
  //     },
  //   ]);
  // };



  // const fetchPerformanceData = async () => {
  //   const [error, result] = await asyncWrap(
  //     axios.get(
  //       `userAnalytics/performance/?UserID=${
  //         user?.profile?.userId || user.userId
  //       }`
  //     )
  //   );
  //   if (!result) {
  //     console.log(error);
  //     return;
  //   }
  //   setUserPerformance(result.data.data);
  //   getChartDataforPerformance();
  // };

  const fetchPointsData = async () => {
    const [error, result] = await asyncWrap(
      axios.get(
        `userAnalytics/six-months-points/?UserID=${
          user?.profile?.userId || user.userId
        }`
      )
    );
    if (!result) {
      console.log(error);
      return;
    }

    const monthdata = result.data.data;
    let month = [];
    let point = [];
    //eslint-disable-next-line
    monthdata?.map((item) => {
      month.push(item.MonthName);
      point.push(item.PointEarned);
    });

    setPointsOptions({
      chart: {
        id: "6 Months Point Earned",
      },
      xaxis: {
        categories: month,
      },
    });

    setPointsSeries([
      {
        name: "Points Earned",
        data: point,
      },
    ]);
  };

  useEffect(() => {
    // fetchPerformanceData();
    fetchPointsData();
    //eslint-disable-next-line
  }, []);

  return (
    <Modal fullscreen={true} show={true}>
      <Modal.Body style={{ marginTop: "50px" }} className="mb-20 p-0 text-left">
        <Chart options={pointsOptions} series={pointsSeries} type="bar" />
        {/* <Chart
          options={performanceOptions}
          series={performanceSeries}
          type="bar"
          // width={100}
        /> */}
      </Modal.Body>
    </Modal>
  );
};

export default Analytics;
