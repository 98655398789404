import HangmanGame from "../component/HangmanGame/HangmanGame";
import MathGame from "../component/MathGame/MathGame";
import Quiz from "../component/Quiz/Quiz";
import SelectSlotMachine from "../component/SlotMachineGame/SelectSlotMachine";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import quiz from "/assets/images/contest/quiz2.png";
import poll from "/assets/images/contest/poll1.png";
import hangman from "/assets/images/contest/hangman1.jpg";
import slotmachine from "/assets/images/contest/slot.png";
import math from "/assets/images/contest/maths.png";
import football from "/assets/images/contest/footballs.png";
import scooter from "/assets/images/contest/scooter1.jpg";
import luckydraw from "/assets/images/contest/luckydraw1.jpg";
import hitNumber from "/assets/images/contest/hitNumber.png";
import biding from "/assets/images/contest/bid.png";
import puzzle from "/assets/images/contest/puzzle.png";
import tapping from "/assets/images/contest/tapping.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SelectPolls from "../component/Poll/SelectPolls";
import EBid from "../component/EBid/EBid";
import LuckyDraw from "../component/LuckyDraw/LuckyDraw";
import SelectHitTheNumber from "../component/HitNumber/SelectHitTheNumber";
import SelectPuzzle from "../component/Puzzle/SelectPuzzle";
import { WebAppConfig } from "../context/WebAppConfig";
import { webAppConfigIsVisible } from "../utils/isVisible";
import SelectTapGame from "../component/TappingGame/SelectTapGame";
import PredictionGame from "../component/PredictionGame/PredictionGame";
import { eventLog } from "../utils/eventLog";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-creative";

import { EffectCreative, Pagination, Navigation } from "swiper";

const Contest = () => {
  const { t } = useTranslation();
  const webAppConfigCtx = useContext(WebAppConfig);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showMathGame, setShowMathGame] = useState(false);
  const [showHangmanGame, setShowHangmanGame] = useState(false);
  const [showSlotMachineGame, setShowSlotMachineGame] = useState(false);
  const [showPoll, setShowPoll] = useState(false);
  const [showLuckyDraw, setShowLuckyDraw] = useState(false);
  const [showEBid, setShowEBid] = useState(false);
  const [showHitNumber, setShowHitNumber] = useState(false);
  const [showPuzzle, setShowPuzzle] = useState(false);
  const [showTapGame, setShowTapGame] = useState(false);
  const [showPrediction, setShowPrediction] = useState(false);
  const contestCard = [
    {
      title: t("quiz"),
      webconfig_name: "Quiz",
      image: quiz,
      color:
        "linear-gradient(150deg, rgb(254, 169, 101), rgb(255, 202, 159) 100%)",
      onclick: () => {
        setShowQuiz(true);
        eventLog("Opened Quiz", "click", "00:00:02");
      },
    },
    {
      title: t("math_game"),
      webconfig_name: "Maths",
      image: math,
      color:
        "linear-gradient(150deg, rgb(255, 133, 133), rgb(255, 55, 55) 100%)",
      onclick: () => {
        setShowMathGame(true);
        eventLog("Opened Math Game", "click", "00:00:02");
      },
    },
    {
      title: t("hangman"),
      webconfig_name: "Hangman",
      image: hangman,
      color:
        "linear-gradient(150deg, rgb(251, 177, 80), rgb(183, 120, 21) 100%)",
      onclick: () => {
        setShowHangmanGame(true);
        eventLog("Opened Hangman Game", "click", "00:00:02");
      },
    },
    {
      title: t("slot_machine"),
      webconfig_name: "Slot Machine",
      image: slotmachine,
      color:
        "linear-gradient(150deg, rgb(133, 139, 255), rgb(72, 55, 255) 100%)",
      onclick: () => {
        setShowSlotMachineGame(true);
        eventLog("Opened Slot Machine", "click", "00:00:02");
      },
    },
    {
      title: t("poll"),
      webconfig_name: "Poll",
      image: poll,
      color:
        "linear-gradient(150deg, rgb(254, 169, 101), rgb(255, 202, 159) 100%)",
      onclick: () => {
        setShowPoll(true);
        eventLog("Opened Poll", "click", "00:00:02");
      },
    },
    {
      title: t("scooter"),
      webconfig_name: "Scooter",
      image: scooter,
      color:
        "linear-gradient(150deg, rgb(143, 153, 203), rgb(40, 53, 129) 100%)",
      onclick: () => {
        eventLog("Opened Scooter Game", "click", "00:00:02");
        window.open("https://gamesnacks.com/embed/games/scooterxtreme");
      },
    },
    {
      title: t("football"),
      webconfig_name: "Football",
      image: football,
      color: "linear-gradient(45deg, rgb(43, 122, 11), rgb(125, 206, 19))",
      onclick: () => {
        eventLog("Opened Football Game", "click", "00:00:02");
        window.open("https://gamesnacks.com/embed/games/freekickfootball");
      },
    },
    {
      title: "EBid",
      webconfig_name: "EBid",
      image: biding,
      color:
        "linear-gradient(150deg, rgb(255, 133, 133), rgb(255, 55, 55) 100%)",
      onclick: () => {
        setShowEBid(true);
        eventLog("Opened Ebid", "click", "00:00:02");
      },
    },
    {
      title: t("lucky_draw"),
      webconfig_name: "Lucky Draw",
      image: luckydraw,
      color:
        "linear-gradient(150deg, rgb(133, 139, 255), rgb(72, 55, 255) 100%)",
      onclick: () => {
        setShowLuckyDraw(true);
        eventLog("Opened Lucky draw", "click", "00:00:02");
      },
    },
    {
      title: t("hit_the_number"),
      webconfig_name: "Hit The Number",
      image: hitNumber,
      color:
        "linear-gradient(150deg, rgb(143, 153, 203), rgb(40, 53, 129) 100%)",
      onclick: () => {
        setShowHitNumber(true);
        eventLog("Opened Hit the number", "click", "00:00:02");
      },
    },
    {
      title: t("puzzle"),
      webconfig_name: "Puzzle",
      image: puzzle,
      color:
        "linear-gradient(150deg, rgb(255, 133, 133), rgb(255, 55, 55) 100%)",
      onclick: () => {
        setShowPuzzle(true);
        eventLog("Opened Puzzle Game", "click", "00:00:02");
      },
    },
    {
      title: t("tapping"),
      webconfig_name: "Tapping",
      image: tapping,
      color:
        "linear-gradient(150deg, rgb(251, 177, 80), rgb(183, 120, 21) 100%)",
      onclick: () => {
        setShowTapGame(true);
        eventLog("Opened Tapping Game", "click", "00:00:02");
      },
    },
    {
      title: t("prediction_game"),
      webconfig_name: "Prediction Game",
      image: tapping,
      color: "linear-gradient(150deg,#FBB150, #B77815 100%)",
      onclick: () => {
        setShowPrediction(true);
        eventLog("Opened Tapping Game", "click", "00:00:02");
      },
    },
  ];

  return (
    <>
      <SelectTapGame show={showTapGame} setShow={setShowTapGame} />
      <SelectPuzzle show={showPuzzle} setShow={setShowPuzzle} />
      <EBid show={showEBid} setShow={setShowEBid} />
      <SelectPolls show={showPoll} setShow={setShowPoll} />
      <SelectSlotMachine
        show={showSlotMachineGame}
        setShow={setShowSlotMachineGame}
      />
      {/* <HitNumber show={showHitNumber} setShow={setShowHitNumber} /> */}
      <SelectHitTheNumber show={showHitNumber} setShow={setShowHitNumber} />
      <LuckyDraw show={showLuckyDraw} setShow={setShowLuckyDraw} />
      <HangmanGame show={showHangmanGame} setShow={setShowHangmanGame} />
      <MathGame show={showMathGame} setShow={setShowMathGame} />
      <Quiz show={showQuiz} setShow={setShowQuiz} />
      <PredictionGame show={showPrediction} setShow={setShowPrediction} />
      <p
        className="primary"
        style={{
          fontSize: "20px",
          marginBottom: "5px",
          textAlign: "left",
        }}
      >
        <span className="borderBottom ml-20">{t("games")}</span>
      </p>
      <Swiper
        grabCursor={true}
        pagination={true}
        navigation={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative, Pagination, Navigation]}
        className="mySwiper"
      >
        {contestCard.map(
          (item, index) =>
            webAppConfigIsVisible(
              `${item.webconfig_name}`,
              webAppConfigCtx
            ) && (
              <SwiperSlide
                key={index}
                onClick={item.onclick ? item.onclick : null}
                style={{ background: `${item.color}` }}
              >
                <img
                  alt={item.title}
                  src={item.image}
                  style={{ width: "100%", marginTop: "30px" }}
                />
                <div
                  className="d-flex flex-column p-2 justify-content-center w-100 position-absolute bottom-0"
                  style={{
                    background: "rgba(0,0,0,0.7)",
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  <div>{item.title}</div>
                  {/* <div style={{ fontSize: "15px", fontWeight: "500" }}>
                    this game playinh quiz in home scrennff asdf af sdf sdf f
                  </div> */}
                  <div
                    className="p-1 px-4 align-self-center"
                    style={{
                      width: "fit-content",
                      border: "1px solid white",
                      borderRadius: "10px",
                      marginBottom: "23px",
                    }}
                  >
                    Play
                  </div>
                </div>
              </SwiperSlide>
            )
        )}
      </Swiper>
    </>
    // <div>
    //   {/* <TapGame show={showTapGame} setShow={setShowTapGame} /> */}

    //   <div
    //     className="d-flex justify-content-center flex-wrap"
    //     style={{ marginBottom: "5rem" }}
    //   >
    //     {/* <p
    //       className="primary"
    //       style={{ fontSize: "20px", marginBottom: 0, textAlign: "left" }}
    //     >
    //       <span className="borderBottom ml-20">{t("context")}</span>
    //     </p> */}

    //     {contestCard.map(
    //       (data, index) =>
    //         webAppConfigIsVisible(`${data.title}`, webAppConfigCtx) && (
    //           <AchivementCard
    //             key={index}
    //             bodyStyle={{ marginTop: 0, color: "white" }}
    //             title2={data.title}
    //             cardStyle={{
    //               marginLeft: "0px",
    //               width: "42vw",
    //               background: data.color,
    //               marginTop: "10px",
    //               borderRadius: "10px",
    //             }}
    //             cardImageStyle={{ marginTop: "20px", height: "100px", borderRadius:"10px" }}
    //             cardImage={data.image}
    //             bottomtextStyle={{ fontSize: "16px",fontWeight:"900" }}
    //             onclick={data.onclick ? data.onclick : null}
    //           />
    //         )
    //     )}

    //     {/* <img
    //       src={require("/assets/images/scrollBottom.png").default}
    //       alt=""
    //     /> */}
    //   </div>
    // </div>
  );
};

export default Contest;
