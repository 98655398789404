import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import { useAuth } from "../../context/auth-context";

const Schema = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const { user } = useAuth();
  const [cmsValues, setCmsValues] = useState();

  useEffect(() => {
    getCmsValues();
    // eslint-disable-next-line
  }, []);

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    // setCmsValues(result.data.data?.SchemeList);
    setCmsValues(
      result.data.data?.SchemeList?.filter(
        item =>
          item.RoleName === (user?.profile?.roleName || user?.roleName) ||
          item.RoleName === null
      )
    );
  };

  return (
    <>
      <Modal fullscreen={true} show={show}>
        <Modal.Body>
          {user?.profile?.callverification || user?.callverification ? (
            <div className="d-flex flex-column">
              {cmsValues &&
                cmsValues.map((item, index) => {
                  return index === 0 ? (
                    (user?.profile?.roleName || user?.roleName) ===
                    "Retailer" ? (
                      <div
                        key={index}
                        style={{ borderRadius: "10px" }}
                        className="d-flex flex-column mt-35"
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            className="image-3-1"
                            alt="schemesimg"
                            src={item.BannerImage}
                          />
                        </div>
                        <table className="custom-table mt-4">
                          <thead>
                            <tr style={{ fontSize: "12px" }}>
                              <th style={{ width: "100px" }}>
                                NO. OF POINTS REQUIRED TO UNLOCK REWARDS
                              </th>
                              <th style={{ width: "150px" }}>
                                LONG TERM REWARDS
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>8000</td>
                              <td>Silver Voucher worth Rs 2100</td>
                            </tr>
                            <tr>
                              <td>12500</td>
                              <td>Silver Voucher worth Rs 3500</td>
                            </tr>
                            <tr>
                              <td>18000</td>
                              <td>Silver Voucher worth Rs 5200</td>
                            </tr>
                            <tr>
                              <td>25000</td>
                              <td>Silver Voucher worth Rs 8000</td>
                            </tr>
                            <tr>
                              <td>35000</td>
                              <td>Silver Voucher worth Rs 11500</td>
                            </tr>
                            <tr>
                              <td>52000</td>
                              <td>Gold Voucher Worth Rs 18000</td>
                            </tr>
                            <tr>
                              <td>72500</td>
                              <td>Gold Voucher Worth Rs 25000</td>
                            </tr>
                            <tr>
                              <td>125000</td>
                              <td>Thailand 3N/4D</td>
                            </tr>
                            <tr>
                              <td>175000</td>
                              <td>Dubai 3N/4D</td>
                            </tr>
                            <tr>
                              <td>250000</td>
                              <td>Mauratius 3N/4D</td>
                            </tr>
                            <tr>
                              <td>325000</td>
                              <td>Turkey 3N/4D</td>
                            </tr>
                            <tr>
                              <td>425000</td>
                              <td>Turkey + Greece 4N/5D</td>
                            </tr>
                            <tr>
                              <td>550000</td>
                              <td>France 4N/5D</td>
                            </tr>
                            <tr>
                              <td>750000</td>
                              <td>Turkey + Greece 4N/5D (2 Tickets)</td>
                            </tr>
                            <tr>
                              <td>1000000</td>
                              <td>France 4N/5D (2 Tickets)</td>
                            </tr>
                          </tbody>
                        </table>
                        {/* <div className="text-center">
                        <img
                          alt="schemesimg"
                          style={{
                            maxWidth: "100%",
                            height: "100%",
                          }}
                          src="/assets/images/rr-cabel/scheme1.png"
                        />
                      </div> */}
                        <div className="my-2 home-card-top-border">
                          <div
                            onClick={() => {
                              window.open(
                                "https://drive.google.com/file/d/1Fz2jiwouiBoU1Phz_7Xd3I7GiJQQxdAA/preview",
                                "_self"
                              );
                            }}
                            className="fs-5 mt-3 p-2 text-center home-page-card"
                          >
                            Download Scheme
                          </div>
                        </div>
                        {/* <div className="p-2 shadow mt-2 rounded-2">
                        <iframe
                          src="https://drive.google.com/file/d/1Fz2jiwouiBoU1Phz_7Xd3I7GiJQQxdAA/preview"
                          width="100%"
                          height="500px"
                        />
                      </div> */}
                      </div>
                    ) : (
                      <div
                        key={index}
                        style={{ borderRadius: "10px" }}
                        className="d-flex flex-column mt-35"
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            className="image-3-1"
                            alt="schemesimg"
                            src={item.BannerImage}
                          />
                        </div>
                        <table className="custom-table mt-4">
                          <thead>
                            <tr>
                              <th style={{ width: "100px" }}>MILESTONE CLUB</th>
                              <th>LONG TERM POINTS</th>
                              <th>LONG TERM REWARDS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Base Upon Registration</td>
                              <td>0</td>
                              <td>Digital Business Cards</td>
                            </tr>
                            <tr>
                              <td rowSpan={3}>Bronze</td>
                              <td>800</td>
                              <td>T Shirt</td>
                            </tr>
                            <tr>
                              <td>4000</td>
                              <td>Bagpack</td>
                            </tr>
                            <tr>
                              <td>10000</td>
                              <td>Tool Kit</td>
                            </tr>
                            <tr>
                              <td rowSpan={3}>Silver</td>
                              <td>14000</td>
                              <td>Technician Tool Bag</td>
                            </tr>
                            <tr>
                              <td>20000</td>
                              <td>Casserole Set of 3</td>
                            </tr>
                            <tr>
                              <td>30000</td>
                              <td>Dinner Set</td>
                            </tr>
                            <tr>
                              <td rowSpan={3}>Gold</td>
                              <td>40000</td>
                              <td>Bosch HandCutter</td>
                            </tr>
                            <tr>
                              <td>60000</td>
                              <td>Bosch 100 Piece Set</td>
                            </tr>
                            <tr>
                              <td>88000</td>
                              <td>Amazon Voucher Worth Rs 10000</td>
                            </tr>
                            <tr>
                              <td rowSpan={3}>Platinum</td>
                              <td>120000</td>
                              <td>Amazon Voucher Worth Rs 15000</td>
                            </tr>
                            <tr>
                              <td>140000</td>
                              <td>Amazon Voucher Worth Rs 25000</td>
                            </tr>
                            <tr>
                              <td>400000</td>
                              <td>Electric Bike</td>
                            </tr>
                          </tbody>
                        </table>

                        {/* <div className="text-center">
                        <img
                          alt="schemesimg"
                          style={{
                            maxWidth: "100%",
                            height: "100%",
                          }}
                          src="/assets/images/rr-cabel/scheme2.png"
                        />
                      </div> */}

                        <div className="my-2 home-card-top-border">
                          <div
                            onClick={() => {
                              window.open(
                                "https://drive.google.com/file/d/1rmStzKy9NGCd7aiQqIaqxbkBA5Iay3QH/preview",
                                "_self"
                              );
                            }}
                            className="fs-5 mt-3 p-2 text-center home-page-card"
                          >
                            Download scheme
                          </div>
                        </div>
                        {/* <div className="p-2 shadow mt-2 rounded-2">
                        <iframe
                          src=""
                          width="100%"
                          height="500px"
                        />
                      </div> */}
                      </div>
                    )
                  ) : (
                    <div
                      key={index}
                      onClick={() => {
                        const newWindow = window.open(
                          item?.WebLink || item?.PDFPath
                        );
                        if (newWindow) newWindow.opener = null;
                      }}
                      style={{ borderRadius: "10px" }}
                      className="d-flex flex-column mt-3"
                    >
                      <div
                        style={{
                          height: "150px",
                          // background: "#E5ECFD",
                          borderRadius: "15px",
                        }}
                        className="d-flex justify-content-center"
                      >
                        <img
                          alt="schemes img"
                          style={{
                            width: "auto",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                          src={item.BannerImage}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <h1
              style={{
                color: "rgb(58, 105, 221)",
                fontWeight: 600,
              }}
              className="text-center mt-2"
            >
              User Unverified
            </h1>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Schema;
