import React from "react";
import tropyImage from "/assets/images/Redeem/trophy.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

function CurrentYearPoints({ title, point }) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="text-center mt-4">
      <p
        className="fw-bold"
        style={{ fontSize: "1.5rem", marginBottom: 0, color: "#434343" }}
      >
        {title}
      </p>
      <div
        style={{ color: "#7B7B7B" }}
        className="d-flex flex-column justify-content-center"
      >
        <div>
          <div className="d-flex flex-row align-items-center justify-content-center mt-3">
            <img
              style={{ marginRight: "10px", width: "35px", height: "35px" }}
              src={tropyImage}
              alt="trophyimage"
            />
            <div
              style={{ color: "#3A69DD", fontSize: "35px" }}
              className="fw-bold"
            >
              {point || 0}
            </div>
            <img
              style={{ marginLeft: "10px", width: "35px", height: "35px" }}
              src={tropyImage}
              alt="tropyimage"
            />
          </div>
          <div
            style={{ color: "#7B7B7B", fontSize: "18px" }}
            className="fw-bold mt-1"
          >
            {t("rewards_points")}
          </div>
          <button
            style={{ background: "#3A69DD", color: "white" }}
            className="btn btn-primary fw-bold rounded-5 px-4 mt-4"
            onClick={() => history.push("/redeem")}
          >
            {t("redeem_points")}
          </button>
          <div
            style={{ color: "#7B7B7B", fontSize: "16px" }}
            className="fw-bold mt-2"
          >
            Total Points Earn
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrentYearPoints;
