import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Rank from "./pages/Rank";
import ProfilePage from "./component/ProfilePage/ProfilePage";
import Survey from "./pages/Survey";
import Settings from "./pages/Settings";
import Intro from "./pages/Intro";
import Login from "./pages/Login";
import Stickers from "./pages/Stickers";
import PointsInformation from "./pages/PointsInformation";
import Group from "./pages/Group";
import LoadingScreen from "./pages/LoadingScreen";
import RedeemPage from "./pages/RedeemPage";
import { setAxiosDefault, setToken } from "./axiosDefaults";
import { useAuth } from "./context/auth-context";
import ScanCode from "./pages/ScanCode";
import AddEndUser from "./pages/AddEndUser";
import AddRetailor from "./pages/AddRetailor";
import Contest from "./pages/Contest";
import { createContext, useEffect, useState } from "react";
import Challenges from "./pages/Challenges";
import GamePointsInformation from "./pages/GamePoint";
import { WebAppConfig } from "./context/WebAppConfig";
import { asyncWrap } from "./utils/utils";
import axios from "axios";
import GetStarted from "./pages/GetStarted";
import CustomerChat from "./pages/CustomerChat";
import KycVerify from "./pages/KycVerify";
import Home2 from "./pages/Home2";
import Brocher from "./component/Home/Brocher";
import Schema from "./component/Home/Schema";
import PriceList from "./component/Home/PriceList";
import Swal from "sweetalert2";
import Analytics from "./component/Analytics/Analytics";
import Events from "./pages/Events";
// import Products from "./pages/Products";
import ScratchCardDetail from "./component/ScrachCardModal/ScratchCardDetail";
import TermsAndCondition from "./pages/TermsAndCondition";
import AboutUs from "./pages/AboutUs";
import i18next from "i18next";
import Notification from "./pages/Notification";
import { useMokKeysStore } from "./store";
import JigyasaNEC from "./component/Home/JigyasaNEC";
import Spins from "./pages/Spin";

export const Language_Context = createContext();
const App = () => {
  const fetchMokKeys = useMokKeysStore((state) => state.getUpdateKeys);
  const [language, setLangauge] = useState();
  const [webAppConfig, setWebAppConfig] = useState([]);
  setAxiosDefault();
  const { user, setUser } = useAuth();
  if (user?.token) setToken(user.token);

  // const [loading, setLoading] = useState(true);
  // const [splaceScreen, setSplaceScreen] = useState();
  // const splashscreeenshow = true;
  // const getCmsSplaceScreen = async () => {
  //   const [error, result] = await asyncWrap(
  //     axios.get("/cmsValues/?cmsType=SplashScreen")
  //   );
  //   if (!result) {
  //     console.log(error);
  //     return;
  //   }
  //   setSplaceScreen(result.data.data.SplashScreen.SplashScreen);
  // };
  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000);
  // }, []);
  // useEffect(() => {
  //   getCmsSplaceScreen();
  // });

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(
      axios.get("/cmsValues/?cmsType=WebAppConfig")
    );
    if (!result) {
      console.log(error);
      return;
    }
    if (result.data.data.WebAppConfig) {
      setWebAppConfig(JSON.parse(result.data.data?.WebAppConfig?.Descriptions));
    }
  };

  const Logout = () => {
    localStorage.clear();
    setToken(null);
    window.location.reload()
  };

  useEffect(() => {
    if (window.location.search) {
      let token = window.location.search.split("=")[1];
      setToken(token);
      getUserProfile(token);
    }
    getCmsValues();
    fetchMokKeys();
    //eslint-disable-next-line
  }, []);

  const getUserProfile = async token => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      Swal.fire("something went wrong!");
      return;
    } else {
      let data = result.data.data;
      data.token = token;
      setUser(data);
      setAxiosDefault();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("key_language")) {
      i18next.changeLanguage(localStorage.getItem("key_language"));
    }
  },[]);

  return (
    <WebAppConfig.Provider value={webAppConfig}>
      <Language_Context.Provider value={[language, setLangauge]}>
        <div className="App">
          {user ? (
            <BrowserRouter basename="/app">
              <div style={{ marginTop: 10, marginBottom: 50 }}>
                {/* <Navbar /> */}
                <Switch>
                  <Route path="/analytics" component={Analytics} />
                  <Route path="/home" component={Home} />
                  <Route path="/home2" component={Home2} />
                  <Route path="/rank" component={Rank} />
                  <Route path="/challenge" component={Challenges} />
                  <Route
                    path="/group"
                    component={() => {
                      window.location.href = "https://kabelstar.com/";
                      return null;
                    }}
                  />
                  <Route path="/profile" component={ProfilePage} />
                  <Route path="/edit-profile/:userId" component={AddEndUser} />
                  <Route
                    path="/edit-retailer/:userId"
                    component={AddRetailor}
                  />
                  <Route path="/survey/:id" component={Survey} />
                  <Route path="/settings" component={Settings} />
                  <Route path="/spin" component={Spins} />
                  <Route path="/intro" component={Intro} />
                  <Route path="/stickers" component={Stickers} />
                  <Route path="/points-info" component={PointsInformation} />
                  <Route path="/game-point" component={GamePointsInformation} />
                  <Route path="/login" component={Login} />
                  <Route path="/loading" component={LoadingScreen} />
                  <Route path="/redeem" component={RedeemPage} />
                  <Route path="/scan" component={ScanCode} />
                  <Route path="/games" component={Contest} />
                  <Route path="/customer-chat" component={CustomerChat} />
                  <Route path="/kycverify" component={KycVerify} />
                  <Route path="/brochure" component={Brocher} />
                  <Route path="/pricelist" component={PriceList} />
                  <Route path="/schemes" component={Schema} />
                  <Route path="/scratchcard" component={ScratchCardDetail} />
                  <Route path="/events" component={Events} />
                  {/* <Route path="/products" component={Products} /> */}
                  <Route path="/terms" component={TermsAndCondition} />
                  <Route path="/aboutus" component={AboutUs} />
                  <Route path="/notificationspage" component={Notification} />
                  <Route path="/logout" component={Logout} />
                  <Route path="/jigyasanec" component={JigyasaNEC} />
                  <Redirect to="/loading" />
                </Switch>
              </div>

              {/* <BottomTab /> */}
            </BrowserRouter>
          ) : (
            <BrowserRouter basename="/app">
              <Switch>
                <Route path="/add-user" component={AddEndUser} />
                <Route path="/add-retailer" component={AddRetailor} />
                <Route path="/login" component={Login} />
                <Route path="/get-started" component={GetStarted} />
                <Route path="/loading" component={LoadingScreen} />
                <Redirect to="/get-started" />
              </Switch>
            </BrowserRouter>
          )}
        </div>
      </Language_Context.Provider>
    </WebAppConfig.Provider>
  );
};

export default App;
