import { useEffect, useState } from "react";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { Modal } from "react-bootstrap";

const TermsAndCondition = () => {
  const [termsAndContion, setTermsAndCondition] = useState();

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setTermsAndCondition(result.data.data.TermsAndCondition);
  };

  useEffect(() => {
    getCmsValues();
  }, []);

  return (
    <Modal fullscreen={true} show={true}>
      <Modal.Body
        style={{ margin: "0px 10px" }}
        className="mb-20 p-0 text-left"
      >
        {termsAndContion && (
          <div
            dangerouslySetInnerHTML={{ __html: termsAndContion.Descriptions }}
          ></div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TermsAndCondition;
