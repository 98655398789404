import { Row, Col } from "antd";
import { Card, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import SpinAndWin from "./SpinAndWin";
import { useTranslation } from "react-i18next";

const Spins = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(true);
  const [spinData, setSpinData] = useState([]);
  const [spinId, setSpinId] = useState();
  const [spinName, setSpinName] = useState();
  const [showWheel, setShowWheel] = useState(false);

  const color = ["#FEA965", "#CC4056", "#A13964", "#4E866A"];
  const GetSpins = async () => {
    const [error, result] = await asyncWrap(axios.get("/spin"));
    if (!result) {
      console.log(error);
      return;
    }
    let data = result.data.data.map((item, i) =>
      i % 3 === 0
        ? { ...item, Color: color[i % 4] }
        : { ...item, Color: color[i % 4] }
    );
    setSpinData(data);
  };

  useEffect(() => {
    GetSpins();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <SpinAndWin
        spinId={spinId}
        spinName={spinName}
        show={showWheel}
        setShow={setShowWheel}
      />
      <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
        <Modal.Body className="mb-20 p-0 text-left">
          <Row>
            {spinData.length === 0 ? (
              <img
                src="/assets/images/rr-cabel/comingsoon.png"
                className="mx-auto"
                style={{ width: "fit-content", alignSelf: "center" }}
                height={150}
              />
            ) : null}
            {spinData ? (
              spinData.map((item, index) => (
                <Col
                  key={index}
                  xs={22}
                  style={{
                    marginTop: "20px",
                    marginLeft: "15px",
                  }}
                >
                  <Card
                    onClick={() => {
                      setSpinId(item.SpinAndWinID);
                      setSpinName(item.SpinAndWinName);
                      setShowWheel(true);
                    }}
                    style={{
                      backgroundColor: item.Color,
                      height: "160px",
                      boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "90px",
                        height: "90px",
                        backgroundColor: "#FFCA9F",
                        borderRadius: "15px",
                        boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card.Img
                        src={item.SpinImage}
                        style={{ width: "64px", height: "64px" }}
                      />
                    </div>
                    <Card.Text
                      style={{
                        marginTop: "5px",
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {item.SpinAndWinName}
                    </Card.Text>
                  </Card>
                </Col>
              ))
            ) : (
              <p>{t("You_havn_t_won_any_spin_and_win_till_now")}</p>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Spins;
