import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { asyncWrap } from "../utils/utils";
import axios from "axios";

const CustomerChat = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const [number, setNumber] = useState();
  const [chatWootId, setChatWootId] = useState();
  // eslint-disable-next-line
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    if (chatWootId) {
      const script = document.createElement("script");
      const BASE_URL = "https://app.chatwoot.com";
      script.src = `${BASE_URL}/packs/js/sdk.js`;
      script.defer = true;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        window.chatwootSDK.run({
          websiteToken: chatWootId,
          baseUrl: BASE_URL,
        });
      };

      const style = document.createElement("style");
      style.innerHTML = `
      .woot-widget-bubble {
        display: none !important;
      }
    `;
      document.head.appendChild(style);
    }
  }, [chatWootId]);

  const toggleChatWidget = () => {
    setIsChatOpen(prevIsChatOpen => !prevIsChatOpen);
    if (typeof window.$chatwoot !== "undefined") {
      window.$chatwoot.toggle();
    }
  };

  useEffect(() => {
    getCmsValues();
  }, []);

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setNumber(
      JSON.parse(result.data.data?.CallDetails?.Descriptions)?.phone ||
        result.data.data?.CallDetails?.Descriptions
    );
    setChatWootId(
      JSON.parse(result.data.data?.CallDetails?.Descriptions)?.ChatWootID
    );
  };

  return (
    <>
      <Modal fullscreen={true} show={show}>
        {/* <Modal.Header> */}
        {/* <SecoundaryHeader
          text={t("points_information")}
          sideHide="false"
          onBack={() => {
            history.push("/home");
            setShow(false);
          }}
        /> */}
        {/* </Modal.Header> */}
        <Modal.Body style={{ marginTop: "30px" }}>
          {number && (
            <div
              // onClick={() => {
              //   window.open(`tel:${number}`, '_blank');
              // }}
              className="d-flex flex-column shadow p-3 rounded-4 text-center"
            >
              <div
                style={{
                  color: "rgb(58, 105, 221)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Call Center
              </div>
              <img
                style={{
                  width: "fit-content",
                  height: "150px",
                  alignSelf: "center",
                  marginTop: "15px",
                }}
                alt="call center.png"
                src="/assets/images/customer care/callcenterimage.png"
              />
              <div
                style={{
                  color: "gray",
                  fontSize: "16px",
                  marginTop: "20px",
                  fontWeight: "bold",
                }}
              >
                {number}
              </div>
            </div>
          )}
          {chatWootId && (
            <div
              id="chatwoot-button"
              onClick={toggleChatWidget}
              className="d-flex flex-column shadow mt-4 p-3 rounded-4 text-center"
            >
              <div
                style={{
                  color: "rgb(58, 105, 221)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                ChatBot
              </div>
              <img
                style={{
                  width: "100%",
                  height: "150px",
                  alignSelf: "center",
                  marginTop: "15px",
                }}
                alt="chatimge.png"
                src="/assets/images/customer care/chatimg.svg"
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerChat;
