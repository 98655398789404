import { asyncWrap } from "./utils";
import axios from "axios";

export const eventLog = async (eventName, eventType, eventTime) => {
  //eslint-disable-next-line
  const [error, result] = await asyncWrap(
    axios.post("/event-logs", {
      eventLogName: eventName,
      eventLogTime: eventTime,
      eventLogType: eventType,
    })
  );
  if (error) {
    console.log(error);
  }
};
