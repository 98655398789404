import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { Wheel } from "react-custom-roulette";
import { Button } from "reactstrap";
import CelebrationConfetti from "../component/Movement/CelebrationConfetti";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import ReactHowler from "react-howler";

const SpinAndWin = (props) => {
  const { t } = useTranslation();
  const { spinId, spinName, show, setShow } = props;
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState();
  const [winning, setWinning] = useState();
  const [active, setActive] = useState(false);

  const [playing, setPlaying] = useState(false);

  const playSound = () => {
    setPlaying(true);
  };

  const pauseSound = () => {
    setPlaying(false);
  };

  const GetItems = async () => {
    const [error, result] = await asyncWrap(
      axios.get(`spin/detail?spinAndWinId=${spinId}`)
    );

    if (!error) {
      setOptions(result.data.data);
      setData(
        result.data.data.map((item) => ({ ...item, option: item.OptionName }))
      );
    } else {
      console.log(error);
    }
  };

  const getResult = async () => {
    playSound();
    setWinning(null);
    setActive(false);
    const [error, result] = await asyncWrap(
      axios.get(`spin/result?spinAndWinId=${spinId}`)
    );

    if (!result) {
      console.log(error);
      return;
    }
    setWinning(result.data.data[0]);
    const selectedItem = options.findIndex((item) => {
      if (item.OptionName === result.data.data[0].OptionName) {
        return true;
      }
      return false;
    });
    setPrizeNumber(selectedItem);
    setMustSpin(true);
  };

  const onStopSpinning = async () => {
    pauseSound();
    setMustSpin(false);
    setActive(true);
    Swal.fire(`You Won ${winning.OptionName}`);
    const data = {
      usersSpinID: 0,
      spinAndWinID: winning.SpinAndWinID,
      spinAndWinDetailID: winning.SpinAndWinDetailID,
      optionValue: winning.OptionValue,
    };

    const [error, result] = await asyncWrap(axios.post("/spin", data));
    if (!result) {
      console.log(error);
      return;
    }
    console.log(result);
  };

  useEffect(() => {
    GetItems();
    //eslint-disable-next-line
  }, [spinId]);

  return (
    <Modal fullscreen={true} show={show}>
      <ReactHowler
        playing={playing}
        src="/assets/audio/spinandwin.mp3"
        loop={true}
      />
      <Modal.Body
        style={{
          marginTop: "20px",
          background: "#fffef2",
          boxShadow: "#00000005",
          borderRadius: " 20px 20px 0px 0px",
          textAlign: "center",
        }}
      >
        <CelebrationConfetti active={active} />
        <div style={{ marginLeft: 30 }}>
          <Wheel
            textColors={["#ffffff"]}
            backgroundColors={["#3e3e3e", "#df3428"]}
            data={data}
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            onStopSpinning={onStopSpinning}
          />
        </div>
        <div style={{ marginTop: "50px" }}>
          <Button
            disabled={mustSpin}
            style={{
              background: "#7C0000 0% 0% no-repeat padding-box",
              width: "218px",
              borderRadius: "20px",
            }}
            onClick={() => {
              getResult();
            }}
          >
            {t("spin_the_wheel")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SpinAndWin;
