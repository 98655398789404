import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";

const JigyasaNEC = () => {
  return (
    <>
      <Modal fullscreen={true} show={true}>
        <Modal.Body>
          <div className="d-flex text-center flex-column">
            <img
              className="w-100 h-auto rounded-2"
              src="/assets/images/rr-cabel/jigyasa.png"
            />
            <div style={{fontSize:"16px"}} className="text-start mb-2 mt-4">
              A Monthly Journal for news on wires & cables. This edition of
              Jigyasa, Vol 4, April 2023, mentions the latest "National
              Electrical Code of India" (NEC 2023).
            </div>
            {/* <div className="fs-5 w-auto mt-4 shadow border border-1 rounded-2 border-primary">
              Jigyasa NEC Hindi
            </div>
            <div className="fs-5 w-auto mt-2 shadow border border-1 rounded-2 border-primary">
              Jigyasa NEC English
            </div> */}
            <div className="my-2 home-card-top-border">
              <div
                onClick={() => {
                  window.open(
                    "https://drive.google.com/file/d/1JIQTAU-xB12ZN2RW3JF_001nlaqgi5CO/preview",
                    "_self"
                  );
                }}
                className="fs-5 mt-3 p-1 home-page-card"
              >
                Jigyasa NEC English
                <HiDownload style={{marginLeft:"10px"}} />
              </div>
            </div>
            <div className="my-2 home-card-top-border">
              <div
                onClick={() => {
                  window.open(
                    "https://drive.google.com/file/d/1hCzBQmRUfBcRV49ZqADt8yhYocyMO-jl/preview",
                    "_self"
                  );
                }}
                className="fs-5 mt-2 p-1 home-page-card"
              >
                Jigyasa NEC Hindi
                <HiDownload style={{marginLeft:"10px"}} />
              </div>
            </div>
            {/* <div className="p-2 shadow mt-4 rounded-2">
              <div className="fs-5 w-auto">Jigyasa NEC English</div>
              <iframe
                src="https://drive.google.com/file/d/1JIQTAU-xB12ZN2RW3JF_001nlaqgi5CO/preview"
                width="100%"
                height="250px"
              />
            </div>
            <div className="p-2 shadow mt-4 rounded-2">
              <div className="fs-5 w-auto">Jigyasa NEC Hindi</div>
              <iframe
                src="https://drive.google.com/file/d/1hCzBQmRUfBcRV49ZqADt8yhYocyMO-jl/preview"
                width="100%"
                height="250px"
              />
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JigyasaNEC;
