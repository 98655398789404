import axios from "axios";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";
import scratchImage from "/assets/images/scrachcard.png";
import ScrachCardModal from "./ScrachCardModal";

function ScratchCardDetail() {
  const [scratchcardData, setScratchCardData] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    getScratchCard();
    //eslint-disable-next-line
  }, []);

  const getScratchCard = async () => {
    const [error, result] = await asyncWrap(axios.get("/scratch-card"));
    if (!result) {
      console.log(error);
      return;
    }
    // setScratchCardData(result.data.data.filter(item => !item.IsScrached));
    setScratchCardData(result.data.data);
  };

  return (
    <>
      <ScrachCardModal show={show} setShow={setShow} />
      <div className="d-flex flex-row justify-content-evenly gap-2 flex-wrap">
        {scratchcardData.length === 0 ? (
          <img
            src="/assets/images/rr-cabel/comingsoon.png"
            width={100}
            height={100}
          />
        ) : null}
        {scratchcardData &&
          scratchcardData.map(item => (
            <>
              {item.IsScrached ? (
                <div
                  className="d-flex flex-column rounded-2 shadow-sm p-2"
                  style={{ maxWidth: "200px", height: "195px" }}
                >
                  <Image
                    src={item.ScrachImage}
                    style={{
                      width: "150px",
                      minHeight: "auto",
                      maxHeight: "180px",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setShow(true);
                  }}
                  className="d-flex flex-column rounded-2 shadow-sm p-2"
                  style={{ maxWidth: "200px", height: "195px" }}
                >
                  <Image
                    src={scratchImage}
                    style={{
                      width: "150px",
                      minHeight: "auto",
                      maxHeight: "180px",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  />
                </div>
              )}
            </>
          ))}
      </div>
    </>
  );
}

export default ScratchCardDetail;
