import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";
import { asyncWrap } from "../utils/utils";

const VerificationCode = ({
  show,
  setShow,
  otp,
  handleChange,
  handleLogin,
  mobile,
}) => {
  const [cmsValues, setCmsValues] = useState();

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  useEffect(() => {
    getCmsValues();
  }, []);
  const resendCode = async () => {
    if (mobile === "") {
      Swal.fire("Please enter mobile no");
    } else if (mobile.length !== 10) {
      Swal.fire("Please enter valid mobile no");
    } else {
      const [error, result] = await asyncWrap(
        axios.post("/resendOtp", {
          phoneNumber: mobile,
        })
      );

      if (!result) {
        Swal.fire(error.response.data.message);
        return;
      }
      Swal.fire(result.data.message);
    }
  };

  return (
    <Modal fullscreen={true} show={show} onHide={() => setShow(false)}>
      <Modal.Body className="text-center">
        <div
          style={{ height: "95vh" }}
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex flex-column bg-white m-4">
            <div style={{ color: "#434343" }} className="fs-5 fw-bold">
              Verification
            </div>
            <div className="mt-4">
              <div className="d-flex justify-content-center">
                <div style={{ color: "#7b7b7b", width: "80%" }}>
                  Enter a 4 Digit Verification Code That Was Sent on +91{mobile}
                </div>
              </div>
              <div className="mt-4">
                <div className="d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    isInputNum={true}
                    inputStyle={{
                      width: "30px",
                      textAlign: "center",
                      background: "white",
                      margin: "7px",
                      borderBottom: "1px solid #7B7B7B",
                      borderTop: "0px",
                      borderRight: "0px",
                      borderLeft: "0px",
                      padding: "6px",
                      fontSize: "15px",
                      color: "#434343",
                      fontWeight: "600",
                    }}
                    focusStyle={{
                      width: "30px",
                      textAlign: "center",
                      background: "white",
                      margin: "7px",
                      borderBottom: "1px solid #7B7B7B",
                      borderTop: "0px",
                      borderRight: "0px",
                      borderLeft: "0px",
                      padding: "6px",
                      fontSize: "15px",
                      color: "#434343",
                      fontWeight: "600",
                    }}
                  />
                </div>
                <button
                  type="button"
                  style={{ background: "#3a69dd" }}
                  onClick={handleLogin}
                  className="btn btn-primary fw-bold rounded-5 px-4 mt-2"
                >
                  Verify Otp
                </button>
                <div
                  className="mt-3 d-flex flex-columns justify-content-center fw-bold"
                  style={{ color: "#3A69DD" }}
                >
                  Didn't get an OTP ?&nbsp;&nbsp;
                  <div
                    onClick={resendCode}
                    style={{ borderBottom: "1px solid #3A69DD" }}
                  >
                    Resend
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              bottom: "50px",
            }}
          >
            <img
              style={{
                height: "60px",
              }}
              key={cmsValues?.Logo.CMSType}
              src={cmsValues?.Logo.URL}
              alt=""
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VerificationCode;
