import { useEffect, useState } from "react";
import { Progress } from "antd";
import { asyncWrap } from "../utils/utils";
import axios from "axios";

const RedeemPage = () => {
  const [quantityinfo, setQuantityInfo] = useState();

  useEffect(() => {
    GetQuantityInfo();
  }, []);

  const GetQuantityInfo = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    const quantity = (
      (result.data.data?.totalPoints[0]?.point / 18000000) *
      100
    ).toFixed(2);
    setQuantityInfo(quantity);
  };
  return (
    <div style={{ marginTop: "100px" }}>
      <Progress
        type="circle"
        width={200}
        percent={quantityinfo}
        // format={() => "Done"}
      />
      <div className="mt-2 fs-3 mt-4">Target : 18000 kg</div>
    </div>
  );
};

export default RedeemPage;
