import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import CustomCarousel from "./CustomCarousel";

const Brocher = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const [cmsValues, setCmsValues] = useState();

  useEffect(() => {
    getCmsValues();
  }, []);

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  return (
    <>
      <Modal fullscreen={true} show={show}>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="my-2">
              <CustomCarousel images={cmsValues?.Brochures || []} />
            </div>
            <div style={{ fontSize: "18px" }} className="text-center my-3">
              Click below to open brochure
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-row justify-content-between">
                <div
                  onClick={() => {
                    window.open(
                      "https://drive.google.com/file/d/1fCFNR74LV2oakNWED9ZCpebpF3TUtbO8/preview",
                      "_self"
                    );
                  }}
                  style={{
                    border: "2px solid gray",
                    width: "46%",
                  }}
                  className="text-center p-2"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: "3/1",
                    }}
                    src="/assets/images/rr-cabel/rr_kabel.jpg"
                  />
                </div>
                <div
                  onClick={() => {
                    window.open(
                      "https://drive.google.com/file/d/1K895-8nJC7sMemgUffm1tmcDj0Pb8raP/preview",
                      "_self"
                    );
                  }}
                  style={{
                    border: "2px solid gray",
                    width: "46%",
                  }}
                  className="text-center p-2"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: "3/1",
                    }}
                    src="/assets/images/rr-cabel/q1logo.jpg"
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div
                  onClick={() => {
                    window.open(
                      "https://drive.google.com/file/d/1l2EnAbAsaKzRBwOdjoq_a8kRYMwKBWKc/preview",
                      "_self"
                    );
                  }}
                  style={{
                    border: "2px solid gray",
                    width: "46%",
                  }}
                  className="text-center p-2"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: "3/1",
                    }}
                    src="/assets/images/rr-cabel/rr_switches.jpg"
                  />
                </div>
                <div
                  onClick={() => {
                    window.open(
                      "https://drive.google.com/file/d/1K895-8nJC7sMemgUffm1tmcDj0Pb8raP/preview",
                      "_self"
                    );
                  }}
                  style={{
                    border: "2px solid gray",
                    width: "46%",
                  }}
                  className="text-center p-2"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: "3/1",
                    }}
                    src="/assets/images/rr-cabel/rr_switchgear.jpg"
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div
                  onClick={() => {
                    window.open(
                      "https://drive.google.com/file/d/1K895-8nJC7sMemgUffm1tmcDj0Pb8raP/preview",
                      "_self"
                    );
                  }}
                  style={{
                    border: "2px solid gray",
                    width: "46%",
                  }}
                  className="text-center p-2"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: "3/1",
                      padding: "7px",
                    }}
                    src="/assets/images/rr-cabel/rr_shramik.png"
                  />
                </div>
              </div>
            </div>

            {/* {cmsValues &&
              cmsValues.Brochures.map((item, index) => (
                <div key={index} className="mt-3">
                  <div
                    style={{ color: "#3A69DD" }}
                    className="fs-5 fw-bold text-center"
                  >
                    {index === 0
                      ? "Wires and Cables"
                      : index === 4
                      ? "Switches"
                      : index === 8
                      ? "Switchgear"
                      : null}
                  </div>
                  <div
                    onClick={() => {
                      const newWindow = window.open(
                        item?.WebLink || item?.PDFPath
                      );
                      if (newWindow) newWindow.opener = null;
                    }}
                    style={{ borderRadius: "20px" }}
                    className="d-flex flex-column"
                  >
                    <div
                      style={{
                        height: "125px",
                        // background: "#E5ECFD",
                        borderRadius: "15px",
                      }}
                      className="d-flex justify-content-center"
                    >
                      <img
                        alt="brocherimg"
                        style={{
                          width: "auto",
                          borderRadius: "10px",
                          height: "100%",
                        }}
                        src={item.BannerImage}
                      />
                    </div>
                  </div>
                </div>
              ))} */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Brocher;
